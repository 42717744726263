import React, { useRef } from 'react';
import "./MenuSectionSlider.css"

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import MenuSectionSliderCard from '../MenuSectionSliderCard/MenuSectionSliderCard';

import s1 from "../../assets/images/home/desi-tadka.png";
import s2 from "../../assets/images/home/sweet-tooth.png";
import s3 from "../../assets/images/home/kerala-express.png";
import s4 from "../../assets/images/home/menu-slider/just chill.png";
import s5 from "../../assets/images/home/menu-slider/Mocktails.png";
import s6 from "../../assets/images/home/menu-slider/breads.png";
import s7 from "../../assets/images/home/menu-slider/Starters.png";
import s8 from "../../assets/images/home/menu-slider/soup.png";
import s9 from "../../assets/images/home/menu-slider/high rice.png";
import s10 from "../../assets/images/home/menu-slider/Paneer Special.png";
import s11 from "../../assets/images/home/menu-slider/Raita.png";





const MenuSectionSlider = () => {
    const slider = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        className: "center",
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <Slider ref={slider} {...settings}>
                <div>
                    <MenuSectionSliderCard src={s1} title={"Desi Tadka"} desc={"There’s more to Indian food than simply paneer and daal, and everyone has their preferences."} id={"desi-tadka"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s2} title={"Sweet Tooth"} desc={"Gulab Jamun, ice-creams and brownies: the three tempting culprits."} id={"sweet"}/>
                </div>
                {/* <div>
                    <MenuSectionSliderCard src={s3} title={"Kerala Express"} desc={"Idlis, Dosas and Uttpams: who says food can’t be tasty and healthy at the same time?"} id={"kerala-express"}/>
                </div> */}
                <div>
                    <MenuSectionSliderCard src={s4} title={"Just Chill"} desc={"Indian food may be hot, but we’ve got you covered with our chill-list."} id={"ice-creams"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s5} title={"Mocktails"} desc={"Cool isn’t cool if it doesn’t have the right mocktail flavors in it.."} id={"mocktail"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s6} title={"Breads"} desc={"The essence of Indian food in a wide variety to choose from."} id={"breads"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s7} title={"Starters"} desc={"From Papads to Tandoori Paneer, begin your journey to tasty food with mouth-watering starters."} id={"dragons-land"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s8} title={"Soup"} desc={"Sprinkle some tangy treat with our Corn, Tomato, Hot & Sour and Lemon-Coriander Soups."} id={"soup"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s9} title={"High Rice"} desc={"The most widely consumed grain in the world deserves it’s own list.."} id={"thali"}/>
                </div>
                <div>
                    <MenuSectionSliderCard src={s10} title={"Paneer Special"} desc={"A wide range of everyone’s favorite paneer delicacies."} id={"paneer-specials"}/>
                </div>

                <div>
                    <MenuSectionSliderCard src={s11} title={"Raita"} desc={"A wide range of everyone’s favorite paneer delicacies."} id={"raita"}/>
                </div>
            </Slider>

            <div className="slider-btn">
                <div className="slider-arrow" onClick={() => slider?.current?.slickPrev()}>
                    <FaArrowLeft />
                </div>
                <div className="slider-arrow" onClick={() => slider?.current?.slickNext()}>
                    <FaArrowRight />
                </div>
            </div>
        </>

    )
}

export default MenuSectionSlider