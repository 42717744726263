import React from 'react';
import { HashLink } from 'react-router-hash-link';
import "./Footer.css";

import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

import logo from "../../assets/images/header/logo-n 1.svg";
import fb from "../../assets/images/common/fb-svg.svg";
import instagram from "../../assets/images/common/insta-svg.svg";

import leaf from '../../assets/images/common/f-leaf.png';

const Footer = () => {
  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
    window.scroll({
      top: elementPosition,
    });
  };

  return (
    <footer className='footer c-s'>
      <div className="container c-p">
        <div className="footer-container">
          <div className="f-1">
            <div className="f-logo">
              <img src={logo} alt="Logo" />
            </div>
            <div className="f-1-desc">
              <p>We are a bunch of foodholics that run a beautiful restaurant in Jaipur. Our menu includes a delicious, or in our words, desilicious, list of personally selected dishes from around the world.</p>
            </div>
          </div>
          <div className="f-2">
            <h3 className="f-title">Quick Links</h3>
            <ul>
              <li><HashLink smooth to="/menu#mini-meals" scroll={el => scrollWithOffset(el, 100)}>Mini Meals</HashLink></li>
              <li><HashLink smooth to="/menu#sweet" scroll={el => scrollWithOffset(el, 100)}>Sweet Tooth</HashLink></li>
              <li><HashLink smooth to="/menu#soup" scroll={el => scrollWithOffset(el, 100)}>Soup</HashLink></li>
              <li><HashLink smooth to="/menu#mocktail" scroll={el => scrollWithOffset(el, 100)}>Mocktails</HashLink></li>
            </ul>
          </div>
          <div className="f-3">
            <h3 className="f-title">Contact Us</h3>
            <ul>
              <li><FaLocationDot /><p className='address'> 7-9, Patrakar Colony, Dholai, Mansarovar Extension, Jaipur</p></li>
              <li><FaPhone /><p><a href="tel:+91 9829012860"> +91 9829012860</a> <br /> <a href="tel:+91 9509765621"> +91 9509765621</a></p></li>
              <li><MdEmail /><p><a href="mailto:desiliciousfirangi@gmail.com">desiliciousfirangi@gmail.com</a></p></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-end">
        <div className="container c-p">
          <div className="footer-end-container ">
            <div className="footer-end-desc">
              {`Copyright ${new Date().getFullYear()} Desilicious Firangi all rights reserved. Powered by `}
              <span><a href="https://itxitpro.com" target="_blank" rel="noopener noreferrer">ITXITPro Pvt. Ltd</a></span>
            </div>
            <div className="footer-end-icons">
              <a href="https://www.facebook.com/DESIliciousfirangi/" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/desiliciousfirangi/" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="f-leaf">
        <img src={leaf} alt="leaf" />
      </div>
    </footer>
  );
}

export default Footer;
