import React from 'react';
import "./CommonHeading.css";

const CommonHeading = ({shortTitle,title}) => {
  return (
    <div className="common-heading">
      <p>{shortTitle}</p>
      <h2>{title}</h2>
    </div>
  )
}

export default CommonHeading