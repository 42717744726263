import React from 'react';
import "./CommonBanner.css";


import { IoIosArrowForward } from "react-icons/io";
import { Link } from 'react-router-dom';


const CommonBanner = ({ Heading, Page }) => {
    return (
        <section className="common-banner">
            <div className="common-banner-content">
                <div className="common-banner-text">
                    <h2 className="common-banner-title">{Heading}</h2>
                    <p className="common-banner-desc"><Link to={"/"}><span >Home</span></Link><IoIosArrowForward /><span className='desc-y-c'>{Page}</span></p>
                </div>
            </div>
        </section>
    )
}

export default CommonBanner