import React, { useState } from 'react';
import "./ReservationSection.css";
import CommonHeading from '../CommonHeading/CommonHeading';
import CommonBtn from '../CommonBtn/CommonBtn';

import leaf from "../../assets/images/home/green-leaf.png";
import pizza from "../../assets/images/home/pizza.png";
import ice from "../../assets/images/home/ice-cream.png";

import box1 from "../../assets/images/home/form-top.png";
import box2 from "../../assets/images/home/form-bottom.png";

import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useApi } from "../../ApiContext";

const ReservationSection = () => {
    const { control, register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { postData } = useApi();
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [submitted, setSubmitted] = useState(false);

    function getCurrentDate() {
        const today = new Date();
        let month = String(today.getMonth() + 1);
        let day = String(today.getDate());
        const year = today.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return `${year}-${month}-${day}`;
    }


    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await postData('/reservation', data);
            setResponseMessage(response.message);
            reset()
            setSubmitted(true)
        } catch (error) {
            console.error('Error posting data:', error);
        }
        setLoading(false);
    };


    return (
        <section id='reservation' className="reservation-section">
            <div className="green-leaf">
                <img src={leaf} alt="bg" />
            </div>
            <div className="container">
                <div className="reservation-section-container c-p">
                    <div className="reservation-section-header">
                        <CommonHeading shortTitle={"Contact Us"} title={"Make a Reservation"} />
                    </div>
                    <div className="reservation-section-content">
                        <div className="reservation-form">
                            <div className="form-top-box">
                                <img src={box1} alt="box" />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)} className="vertical-form">
                                <div className="form-container form-container2">

                                    <div className="form-group">
                                        <label>Your Name</label>
                                        <input type="text" className='form-control' placeholder='Enter Your name' {...register('name', { required: 'Required' })} />
                                        {errors.name && <label className="error-message">{errors.name.message}</label>}
                                    </div>


                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" className='form-control' placeholder='Email' {...register('email', { required: 'Required', pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email' } })} />
                                        {errors.email && <label className="error-message">{errors.email.message}</label>}
                                    </div>


                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="tel" className='form-control' placeholder='Phone number' {...register('phone', {
                                            required: 'Required', pattern: {
                                                value: /^\+?\d{1,3}(\s?\d{6,13})?$/,
                                                message: 'Invalid phone number',
                                            }
                                        })} />
                                        {errors.phone && <label className="error-message">{errors.phone.message}</label>}
                                    </div>


                                    <div className="form-group">
                                        <label>Guest(s)</label>
                                        <Controller
                                            name="guests"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select number guests' }}
                                            render={({ field }) => (
                                                <select
                                                    className='form-control'
                                                    {...field} onChange={(e) => {
                                                        setValue('guests', e.target.value)
                                                        field.onChange(e)
                                                    }} >
                                                    <option>Number Of Guests</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                    <option>6</option>
                                                </select>
                                            )}
                                        />
                                        {errors.guests && <label className="error-message">{errors.guests.message}</label>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="stateSelect">Select Date</label>
                                        <input
                                            type="date"
                                            name='date'
                                            {...register('date', { required: 'Date is required' })}
                                            className='form-control'
                                            min={currentDate}
                                        />
                                        {errors.date && <label className="error-message">{errors.date.message}</label>}
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="timeSelect">Select Time</label>
                                        <Controller
                                            name="time"
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: 'Please select a product' }}
                                            render={({ field }) => (
                                                <select
                                                    className='form-control'
                                                    {...field} onChange={(e) => {
                                                        setValue('time', e.target.value)
                                                        field.onChange(e)
                                                    }}>
                                                    <option >Select Time</option>
                                                    <option>Lunch</option>
                                                    <option>Breakfast</option>
                                                    <option>Dinner</option>
                                                </select>
                                            )}
                                        />
                                        {errors.time && <label className="error-message">{errors.time.message}</label>}
                                    </div>

                                </div>
                                <div className="form-footer">
                                    <CommonBtn text={"Reserve your table now"} type={"submit"} />
                                </div>
                            </form>
                            {submitted && <p className="success-message" style={{ marginTop: "10px" }}>Form submitted successfully!</p>}
                            <div className="form-bottom-box">
                                <img src={box2} alt="box" />
                            </div>
                        </div>
                        <div className="reservation-location">
                            <div className="l-t">
                                <h6>Address:</h6>
                                <p>7-9, Patrakar, Colony, Dholai, Mansarovar Extension, Jaipur</p>
                            </div>

                            <div className="l-t">
                                <h6>Phone:</h6>
                                <p><Link to="tel:+91 9829012860"> +91 9829012860</Link></p>
                            </div>

                            <div className="l-t">
                                <h6>Email:</h6>
                                <p><Link to="mailto:desiliciousfirangi@gmail.com">desiliciousfirangi@gmail.com</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ice-cream">
                <img src={ice} alt="bg" />
            </div>
            <div className="pizza">
                <img src={pizza} alt="bg" />
            </div>
        </section>
    )
}

export default ReservationSection