import React, { useState, useEffect } from 'react';
import "./Header.css";

import { FiMenu, FiX } from 'react-icons/fi';
import logo from "../../assets/images/header/logo-n 1.svg";
import phone from "../../assets/images/header/phone.svg";
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [isActive, setIsActive] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    const toggleNav = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 70) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const closeNav = () => {
        setIsActive(false);
    };

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <header id='header' className={`header ${isSticky ? 'sticky' : ''}`}>
            <div className="container header-container">
                <nav className={`navbar c-p ${isActive ? 'active' : 'hide'}`}>
                    <div className="nav-logo">
                        <Link to="/" onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                    <ul className={`nav-items ${isActive ? 'active' : 'hide'}`}>
                        <li><NavLink className="nav-item" to={"/"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Home</NavLink></li>
                        <li><NavLink className="nav-item" to={"/about"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>About</NavLink></li>
                        <li><NavLink className="nav-item" to={"/services"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Services</NavLink></li>
                        <li><NavLink className="nav-item" to={"/catering"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Catering</NavLink></li>
                        <li><NavLink className="nav-item" to={"/menu"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Menu</NavLink></li>
                        <li><NavLink className="nav-item" to={"/gallery"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Gallery</NavLink></li>
                        <li><NavLink className="nav-item" to={"/contact"} onClick={() => {
                            closeNav();
                            handleLinkClick();
                        }}>Contact</NavLink></li>
                    </ul>
                    <div className="nav-contact-info">
                        <div className="phone-icon">
                            <img src={phone} alt="phone" />
                        </div>
                        <div className="header-contact-info">
                            <Link to={"tel:+91 9829012860"} className='m-no'>
                                <p>+91 9829012860</p>
                            </Link>
                            <p>Contact us for reservation</p>
                        </div>
                    </div>
                    <button className="nav-toggle" onClick={toggleNav}>
                        {isActive ? <FiX /> : <FiMenu />}
                    </button>
                </nav>
            </div>
        </header >
    )
}

export default Header;
