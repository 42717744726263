import React from 'react';
import "./CommonSection.css";

import man from "../../assets/images/common/man.png";
import dots from "../../assets/images/common/dots.png";

import CommonHeading from '../CommonHeading/CommonHeading';

const CommonSection = () => {
    return (
        <section className="common-section c-s">
            <div className="container">
                <div className="common-section-container c-p">
                    <div className="common-section-content">
                        <div className="common-section-title">
                            <CommonHeading shortTitle={"Desilicious Firangi"} title={"Healthy Eating is important part of lifestyle"} />
                        </div>
                        <div className="common-section-desc">
                            <p>We are amongst the best multi cuisine restaurants in Jaipur that always drive towards serving delectable and delicious foods to all our customers. Being Jaipurites, we have come up with a mesmerizing menu that beckons you to fulfill your appetite. We offer our customers a variety of cuisines to bring in influences from Italy, China and India. Furthermore, as you walk inside the premises of our restaurant, the perfect seating will catch your attention. The aura of Desilicious Firangi is beautifully inspired by the colors and flavors of Indian and international lifestyle.</p>
                            <p>Our wonderful restaurant is located on the outskirts of Jaipur, at Patrakar Colony which offer you an opportunity to enjoy your toothsome meals away from the hustle and bustle of the city. Desilicious Firangi is an ideal place for a plethora of events ranging from get together to formal dinners, birthday parties to the premium celebration, etc. Moreover, we have our own food delivery app in Jaipur that lets you to order your favorite dish and get it delivered at your doorsteps. Our online food delivery service in Jaipur is far better than the other applications and that’s the reason why people prefer our app over others. </p>
                            <p>Here, at desilicious Firangi, you can have the best food ranging from South India to Chinese to delicate
                                non-vegetarian delicacies too. What makes us superior in this field is our railway catering service, lunch box for corporates and bulk lunch order delivery. We always take care of every sector of society and that’s the reason why people love us.</p>
                        </div>
                    </div>
                    <div className="common-section-img">
                        <img src={man} alt="" />
                        <div className="dots">
                            <img src={dots} alt="" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default CommonSection