import React from 'react';
import "./Testimonials.css";
import CommonHeading from '../CommonHeading/CommonHeading';

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { FaStar } from "react-icons/fa";

import img from "../../assets/images/common/person.png";

const Testimonials = () => {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <section className="testimonials">
            <div className="container c-p">
                <div className="testimonials-header">
                    <CommonHeading shortTitle={"Our Update"} title={"What Client's Say?"} />
                </div>

                <div className="testimonials-slider">
                    <Slider {...settings}>
                        <div>
                            <div className="testimonials-card">
                                <div className="testimonials-desc">
                                    <p>“ As a regular customer at Desilicious Firangi, I am always satisfied with the unchanged quality of food and staff that the restaurant has to offer. Any time I don’t feel like cooking food, I either go to Desilicious Firangi or order food at home. I have tried virtually every dish that they have in their menu and not a single one fails to earn my praises. A definite recommendation from my side!</p>
                                </div>
                                <div className="client">
                                    <div className="client-img">
                                        <img src={img} alt="avatar" />
                                    </div>
                                    <div className="clent-detail">
                                        <p className="client-name">Ayushi Agarwal</p>
                                        <div className="rating"> <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div className="testimonials-card">
                                <div className="testimonials-desc">
                                    <p>“ Nice ambiance, quality food and well-trained staff. A must-visit for every Jaipurite. From North Indian to Continental and everything in between, Desilicious Firangi has it all! Also, the prices are very reasonable. Thank you so much for taking care of my eating needs, Desilicious Firangi!  </p>
                                </div>
                                <div className="client">
                                    <div className="client-img">
                                        <img src={img} alt="avatar" />
                                    </div>
                                    <div className="clent-detail">
                                        <p className="client-name">Rohit Jain</p>
                                        <div className="rating"> <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div className="testimonials-card">
                                <div className="testimonials-desc">
                                    <p>“  Being situated outside the crowd and congestion of the city, Desilicious Firangi has the perfect setting for a calm and peaceful restaurant. It’s a bit far from where I live, but rides to DF are always fun when your driving down the Jaipur-Ajmer highway and imagining the delicious food that I am going to have soon. Highly recommended! </p>
                                </div>
                                <div className="client">
                                    <div className="client-img">
                                        <img src={img} alt="avatar" />
                                    </div>
                                    <div className="clent-detail">
                                        <p className="client-name">Aakash Chotia</p>
                                        <div className="rating"> <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div className="testimonials-card">
                                <div className="testimonials-desc">
                                    <p>“  Hands down one of the best veg restaurants in Jaipur. My office is just 2 kilometers from Desilicious Firangi and I leave it to them to take care of my lunch and dinner every day. So far, not a single day has gone by when I haven’t found their food quality and taste good! </p>
                                </div>
                                <div className="client">
                                    <div className="client-img">
                                        <img src={img} alt="avatar" />
                                    </div>
                                    <div className="clent-detail">
                                        <p className="client-name">Vikas Kumawat</p>
                                        <div className="rating"> <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Testimonials