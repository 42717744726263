import React, { useState } from 'react';
import "./Gallery.css";
import CommonBanner from '../../components/CommonBanner/CommonBanner';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import g1 from "../../assets/images/gallery/d-g-1-min.png";
import g2 from "../../assets/images/gallery/d-g-2-min.png";
import g3 from "../../assets/images/gallery/d-g-3-min.png";
import g4 from "../../assets/images/gallery/d-g-4-min.png";
import g5 from "../../assets/images/gallery/d-g-5-min.png";
import g6 from "../../assets/images/gallery/d-g-6-min.png";
import g7 from "../../assets/images/gallery/d-g-7-min.png";
import g8 from "../../assets/images/gallery/d-g-8-min.png";
import g9 from "../../assets/images/gallery/d-g-9-min.png";
import g10 from "../../assets/images/gallery/d-g-10-min.png";
import g11 from "../../assets/images/gallery/d-g-11-min.png";
import g12 from "../../assets/images/gallery/d-g-12-min.png";
import g13 from "../../assets/images/gallery/d-g-13-min.png";
import g14 from "../../assets/images/gallery/d-g-14-min.png";
import g15 from "../../assets/images/gallery/d-g-15-min.png";
import g16 from "../../assets/images/gallery/d-g-16-min.png";
import g17 from "../../assets/images/gallery/d-g-17-min.png";
import g18 from "../../assets/images/gallery/d-g-18-min.png";
import g19 from "../../assets/images/gallery/d-g-19-min.png";
import g20 from "../../assets/images/gallery/d-g-20-min.png";
import g21 from "../../assets/images/gallery/d-g-21-min.png";
import g22 from "../../assets/images/gallery/d-g-22-min.png";
import g23 from "../../assets/images/gallery/d-g-23-min.png";
import g24 from "../../assets/images/gallery/d-g-24-min.png";








import leaf from "../../assets/images/gallery/Image (6).png";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };


  return (
    <>
      <CommonBanner Heading={"Gallery"} Page={"Gallery"} />

      <section className="gallery-section">
        <div className="gallery-leaf">
          <img src={leaf} alt="leaf" />
        </div>
        <div className="container">
          <div className="gallery-section-container c-p">
            <Tabs>
              <TabList className="tabList">
                <Tab
                  className={`tab ${activeTab === 1 ? 'active' : ''}`}
                  onClick={() => handleTabClick(1)}
                >
                  All
                </Tab>
                <Tab
                  className={`tab ${activeTab === 2 ? 'active' : ''}`}
                  onClick={() => handleTabClick(2)}

                >
                  Food
                </Tab>
                <Tab
                  className={`tab ${activeTab === 3 ? 'active' : ''}`}
                  onClick={() => handleTabClick(3)}

                >
                  Ambiance
                </Tab>
                <Tab
                  className={`tab ${activeTab === 4 ? 'active' : ''}`}
                  onClick={() => handleTabClick(4)}
                >
                  B'Day
                </Tab>
                <Tab
                  className={`tab ${activeTab === 5 ? 'active' : ''}`}
                  onClick={() => handleTabClick(5)}
                >
                  Kitty
                </Tab>
              </TabList>

              <div className="tab-content">
                <TabPanel>
                  <div className="gallery-images">
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g1} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g2} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g3} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g4} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g5} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g6} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g7} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g8} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g9} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g10} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g11} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g12} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g13} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g14} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g15} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g16} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g17} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g18} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g19} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g20} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g21} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g22} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g23} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g24} alt="gallery-1" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="gallery-images">
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g12} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g13} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g14} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g15} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g16} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g17} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g18} alt="gallery-1" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="gallery-images">
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g19} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g20} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g21} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g22} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g23} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g24} alt="gallery-1" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="gallery-images">
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g2} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g4} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g5} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g7} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g8} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g10} alt="gallery-1" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="gallery-images">
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g1} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g6} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g10} alt="gallery-1" />
                      </div>
                    </div>
                    <div className="gallery-image-container">
                      <div className="gallery-image">
                        <img src={g11} alt="gallery-1" />
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>

            </Tabs>
          </div>
        </div>
      </section>
    </>
  )
}

export default Gallery