import React from 'react';
import "./Services.css"
import CommonBanner from '../../components/CommonBanner/CommonBanner';
import CommonHeading from '../../components/CommonHeading/CommonHeading';

import img1 from "../../assets/images/services/indian-hindu-veg-thali-food-platter-selective-focus 1.png";
import img2 from "../../assets/images/services/penne-pasta-tomato-sauce-with-chicken-tomatoes-wooden-table 1.png";


const Services = () => {
  return (
    <>
      <CommonBanner Heading={"Best Vegetarian Restaurant in Jaipur"} Page={"Services"} />


      {/* Story Section */}
      <section className="story-section">
        <div className="container">
          <div className="story-section-container c-p">
            <div className="story-section-heading">
              <CommonHeading shortTitle={"Our Story"} title={"Best Food"} />
            </div>
            <div className="story-section-content">
              <p className="story-section-top-desc">
                Desilicious Firangi is a one-stop destination for the most authentic multi-cuisine gastronomical experience. With an incredible fusion of desi and international lifestyle, we promise to take you right into the interiors of an amazing place that offer you delightful dining experience. With the hard work and dedication of our staff, we have recently achieved a big milestone for one of the best multi cuisine restaurants in Jaipur.
              </p>
              <div className="story-section-images">
                <div className="story-section-image-c">
                  <div className="story-section-image">
                    <img src={img1} alt="" />
                  </div>
                </div>
                <div className="story-section-image-d">
                  <div className="story-section-image">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
              <p className="story-section-bottom-desc">
                Our restaurant is a wonderful place for people seeking for a multi cuisine restaurant in Jaipur. Here, at Desilicious Firangi, you will find all the dishes are being prepared with love and devotion to fulfill the appetite of our customers. Delectable food at Desilicious Firangi not only nourishes your body but also your internal soul. Being a prominent multi-cuisine restaurant in Jaipur, we always take care of each and every customer who visits our restaurant.
              </p>
              <p className="story-section-bottom-desc">
                We’re not only famous for serving delicious food but also have a good reputation when it comes to an ideal birthday party venue in Jaipur. We have witnessed a plethora of birthday parties at our restaurants in Jaipur. People cherish this place because of its astonishing infrastructure that truly showcases the blend of desi and intercontinental lifestyle. We, at Desilicious Firangi, offer you an opportunity to enjoy the enchanting moments of your life like birthday parties, office parties, anniversaries and many more.
              </p>
              <p className="story-section-bottom-desc">
                For those looking for an office party venue or small party venue in Jaipur, this place is like a paradise. Reason being, we’re an experienced small party planner in Jaipur with a keen eye for detail and an inspiring work ethic. We always give our utmost to deliver the best we can give to our customers. Not only we provide a venue for parties, but also famous for best take away restaurants in Jaipur.
              </p>
              <p className="story-section-bottom-desc">
                Go ahead and plan your party at our amazing restaurant and make countless unforgettable memories!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Services