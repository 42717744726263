import React from 'react';
import "./CategoryCard.css";

const CategoryCard = ({ src, title, desc }) => {
    return (
        <div className="category-card">
            <div className="category-card-content">
                <div className="category-card-img">
                    <img src={src} alt="category" />
                </div>
                <h4 className="category-card-title">{title}</h4>
                <p className="category-card-desc">{desc}</p>
            </div>
        </div>
    )
}

export default CategoryCard