import React from 'react';
import "./About.css";
import { Helmet } from 'react-helmet-async';

import ReservationSection from '../../components/ReservationSection/ReservationSection';
import CommonSection from '../../components/CommonSection/CommonSection';
import Testimonials from '../../components/Testimonials/Testimonials';
import CommonBanner from '../../components/CommonBanner/CommonBanner';
import CommonHeading from '../../components/CommonHeading/CommonHeading';
import CommonBtn from '../../components/CommonBtn/CommonBtn';


import img1 from "../../assets/images/about/cooked-vegetables 1.png";
import img2 from "../../assets/images/about/top-view-food-banquet 1.png";
import img3 from "../../assets/images/about/traditional-christmas-dundee-cake-with-dried-fruits-almonds-wooden-board 1.png";
import img4 from "../../assets/images/about/ready-eat-pasta-with-tomatoes-basil-plate-ingredients-table-mediterranean-cuisine-closeup-vertical-view 1.png";

import AboutLeaf from "../../assets/images/about/about-leaf.png"; 

const About = () => {
  return (
    <>
    <Helmet>
      <title>About Us | Foodie Buddy</title>
    </Helmet>
      <CommonBanner 
      Heading={"Who We Are"} 
      Page={"About"} />

      {/* About page section */}
      <section className="about-page-section">
        <div className="about-leaf">
          <img src={AboutLeaf} alt="leaf" />
        </div>
        <div className="container c-p">
          <div className="about-page-section-container">
            <div className="about-page-section-content">
              <div className="about-page-section-content-heading">
                <CommonHeading shortTitle={"About"} title={"Desilicious Firangi"} />
              </div>
              <p className="about-page-section-content-desc">
                We are a group of foodies with an admiration for quality and tasty food. As born Jaipurites, we have come up with the most ideal menu that soothes you. Desilicious Firangi, formly known as Desi Firangi, is situated in the outskirts of Jaipur at Kamala Nehru Nagar. The perfect setting, along with the multi-cuisine food, offers a mesmerizing fusion of desi and international lifestyles. Book your table for tonight and experience a unique style of eatery only at Desilicious Firangi!
              </p>
              <CommonBtn text={"Connect Now"} url={"/contact"}/>
            </div>
            <div className="about-page-section-images">
              <div className="about-page-section-img">
                <img src={img1} alt="food" />
              </div>
              <div className="about-page-section-img">
                <img src={img2} alt="food" />
              </div>
              <div className="about-page-section-img">
                <img src={img3} alt="food" />
              </div>
              <div className="about-page-section-img">
                <img src={img4} alt="food" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Reservation Section */}
      <ReservationSection />

      {/* Common Section */}
      <CommonSection />

      {/* Testimonials */}
      <Testimonials />
    </>
  )
}

export default About