import React from 'react';
import "./MenuSectionSliderCard.css";
import CommonBtn from '../CommonBtn/CommonBtn';
import { HashLink } from 'react-router-hash-link';


const MenuSectionSliderCard = ({ src, title, desc, id }) => {
    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scroll({
          top: elementPosition,
        });
      };
    return (
        <div className='menu-section-slider-card'>
            <div className="menu-slider-card-content">
                <div className="menu-section-slider-card-img">
                    <img src={src} alt="menu" />
                </div>
                <div className="slider-card-content">
                    <h4 className="menu-section-slider-card-title">{title}</h4>
                    <p className="menu-section-slider-card-desc">{desc}</p>
                    <HashLink smooth to={`/menu#${id}`} scroll={el => scrollWithOffset(el, 100)}>
                        <CommonBtn text={"View Menu"} />
                    </HashLink>
                </div>
            </div>
        </div>
    )
}

export default MenuSectionSliderCard