import React from 'react';
import "./CommonBtn.css";
import { MdOutlineArrowOutward } from "react-icons/md";
import { Link } from 'react-router-dom';

const CommonBtn = ({ text, url, type }) => {
    const isUrlProvided = url && url.trim() !== '';

    return isUrlProvided ? (
        <Link to={url}>
            <button className="c-btn" type={type}>
                <span className="btn-text">{text}</span>
                <MdOutlineArrowOutward className='a-arrow' />
            </button>
        </Link>
    ) : (
        <button className="c-btn">
            <span className="btn-text">{text}</span>
            <MdOutlineArrowOutward className='a-arrow' />
        </button>
    );
}

export default CommonBtn;
