import React, { useState } from 'react';
import "./HomeBannerSlider.css"


import Slider from "react-slick";
import "./HomeBannerSlider.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import { FaArrowDownLong } from "react-icons/fa6";
import CommonBtn from '../CommonBtn/CommonBtn';
import { Link } from 'react-scroll';


import rope from "../../assets/images/home/home-banner-rope.png";
import leaf from "../../assets/images/home/rosemary.png";


const HomeBannerSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current) => setCurrentSlide(current),
    };
    return (
        <section className="home-banner">
            <Slider {...settings}>
                <div>
                    <div className={`home-banner-slider-card c-1 ${currentSlide === 0 ? 'animate' : ''}`}>
                        <div className="container home-banner-content-container c-p">
                            <div className="home-banner-content">
                                <h2 className='home-banner-title'>Fresh from the Farm
                                    to Your Plate
                                    <br />
                                    <span> Healthy & Delicious!</span>
                                </h2>
                                <p className="home-banner-desc">
                                    A wide range of Indian, Chinese and Continental cuisines to savor in all tastes, moods and seasons.
                                </p>
                                <Link to="reservation"
                                    spy={true}
                                    offset={-140}
                                    duration={500}>
                                    <CommonBtn text={"Book Now!"} />
                                </Link>
                                <div className="rope">
                                    <img src={rope} alt="bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`home-banner-slider-card c-2 ${currentSlide === 1 ? 'animate' : ''}`}>
                        <div className="container home-banner-content-container c-p">
                            <div className="home-banner-content">
                                <h2 className='home-banner-title'>Fresh from the Farm
                                    to Your Plate
                                    <br />
                                    <span> Healthy & Delicious!</span>
                                </h2>
                                <p className="home-banner-desc">
                                    A wide range of Indian, Chinese and Continental cuisines to savor in all tastes, moods and seasons.
                                </p>
                                <Link to="reservation"
                                    spy={true}
                                    offset={-100}
                                    duration={500}>
                                    <CommonBtn text={"Book Now!"} />
                                </Link>
                                <div className="rope">
                                    <img src={rope} alt="bg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Slider>
            <div className="leaf">
                <img src={leaf} alt="bg" />
            </div>
            <Link to="home-about"
                spy={true}
                smooth={true}
                offset={-140}
                duration={500}>
                <div className="round-arrow">
                    <FaArrowDownLong />
                </div>
            </Link>
        </section>

    )
}

export default HomeBannerSlider