import React, { useRef, useState } from 'react';
import "./Catering.css";
import Slider from 'react-slick';
import { Helmet } from 'react-helmet-async';
import { IoIosArrowForward } from 'react-icons/io';
import {  FaArrowDown , FaArrowUp } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import serviec1 from "../../assets/images/catering/corporate-catering.2e8637bb80ec4cb1a43e.webp"
import serviec2 from "../../assets/images/catering/wedding-catering.webp"
import serviec3 from "../../assets/images/catering/indian-delights.webp"
import CommonHeading from '../../components/CommonHeading/CommonHeading';


import icon from "../../assets/images/catering/food-icon.svg"

import gallery1 from "../../assets/images/catering/event_1.webp";
import gallery2 from "../../assets/images/catering/event_2.jpg";
import gallery3 from "../../assets/images/catering/event_3.webp";
import gallery4 from "../../assets/images/catering/event_4.webp";
import gallery5 from "../../assets/images/catering/event_5 (1).jpg";
import gallery6 from "../../assets/images/catering/event_6 (1).webp";
import gallery7 from "../../assets/images/catering/event_7 (1).jpg";
import gallery8 from "../../assets/images/catering/event_8 (1).webp";




import video from "../../assets/desilicious_video.mp4"

const categories = [
    { id: 1, name: "Sweet Tooth", icon: icon },
    { id: 2, name: "Soups", icon: icon },
    { id: 3, name: "Shakes & Mocktails", icon: icon },
    { id: 4, name: "Raita - Salad", icon: icon },
    { id: 5, name: "Paneer Specials", icon: icon },
    { id: 6, name: "Breads", icon: icon },
];

const items = {
    1: [
        { title: "Vanilla", description: "" },
        { title: "Strawberry", description: "" },
        { title: "Chocolate", description: "" },
        { title: "Gulab Jamun", description: "" },
    ],
    2: [
        { title: "Cream Of Tomato", description: "" },
        { title: "Veg. Clear Soup", description: "" },
        { title: "Hot n Sour", description: "" },
        { title: "Manchow Soup", description: "" },
        { title: "Tomato-Dhaniya Ka Shorba", description: "" },
    ],
    3: [
        { title: "Virgin Mojito", description: "A concoction of lime juice, sugar, ice, and mint leaves" },
        { title: "Vanilla", description: "" },
        { title: "Strawberry Shakes", description: "" },
        { title: "Strawberry Cooler", description: "A refreshing drink with strawberry in lime juice" },
        { title: "Oreo", description: "" },
        { title: "Chocolate", description: "A refreshing drink with strawberry in lime juice" },
        { title: "Blue Lagoon", description: "A sweet lime drink and blue cutacao" },
    ],
    4: [
        { title: "Garlic Chutney", description: "" },
        { title: "Veg. Raita", description: "" },
        { title: "Plain Curd", description: "" },
        { title: "Pineapple Raita", description: "" },
        { title: "Onion Salad", description: "" },
        { title: "Kachumber Salad", description: "" },
        { title: "Green Salad", description: "" },
        { title: "Boondi Raita", description: "" },
    ],
    5: [
        { title: "Shahi Paneer", description: "Juicy chunks of paneer cooked in a creamy gravy with a rich consistency & oodles of butter" },
        { title: "Paneer Tikka Lababdar", description: "A delicious creamy and flavourful dish made with grilled paneer in a masala gravy" },
        { title: "Paneer Lababdar", description: "An all time favourite dish, prepared by using lots of veggies & cream" },
        { title: "Paneer Do Pyaaza", description: "Fresh cottage cheese cooked in onion gravy with fenugreek, onion & butter" },
        { title: "Paneer Butter Masala", description: "A creamy and mildly-sweet gravy made with butter, tomatoes, cashews, spices and marinated paneer" },
        { title: "Paneer Bhurji", description: "Stir-fried cottage cheese a long with butter, chopped onions, tomatoes, ginger and garlic" },
        { title: "Palak Paneer", description: "A dish consisting of paneer in a thick and delicious gravy made from spinach" },
        { title: "Matar Paneer", description: "Creamy, delicious and rich peas with cottage cheese in a tomato gravy, cooked in special garam masala" },
        // { title: "Kadhai Paneer", description: "A semi dry curry made with cottage cheese, capsicum, onions & tomatoes cooked in a spicy masala" },
        // { title: "Handi Paneer", description: "One of the most famous mouth-watering spicy dishes from the house of Punjab - diced cottage cheese with capsicum and dried spices" },
    ],
    6: [
        { title: "Tawa Roti", description: "" },
        { title: "Tandoori", description: "" },
        { title: "Stuffed Naan", description: "" },
        { title: "Plain Naan", description: "" },
        { title: "Missi Roti", description: "" },
        { title: "Roomali Roti", description: "" },
        { title: "Lachcha Parantha", description: "" },
        { title: "Garlic Naan", description: "" },
        { title: "Butter Naan", description: "A semi dry curry made with cottage cheese, capsicum, onions & tomatoes cooked in a spicy masala" },
    ],
};


const Catering = () => {
    const Navigate = useNavigate()
    const [activeCategory, setActiveCategory] = useState(categories[0].id);
    const sliderRef = useRef(null);
    const handleCategoryClick = (categoryId) => {
        setActiveCategory(categoryId);
    };
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
    };

    const services = [
        {
            title: 'Corporate Catering',
            description: 'Experience a richer level of corporate catering with Desilicious Firangi. Instead of a limited set with no choice in the matter, we offer a diverse menu that perfectly fits your company’s needs for every single business meeting, conference, or office party. With Desilicious Firangi by Rikhil’s Rasoi traceable to quality and service in corporate catering, you know we are the best place to be.',
            image: serviec1,
            buttonText: 'Enquire now',
        },
        {
            title: 'Wedding and Anniversary Catering',
            description: 'We also offer special wedding catering and other occasions such as anniversaries that will make the celebration more memorable. Whether you have a small budget for a cocktail party or a large one for a sitting-down dinner, our team designs superb meals according to your preference. Provide us with your vision for this captivating and special day and let us create your menu with a little help from Desilicious Firangi by Rikhil’s Rasoi.',
            image: serviec2,
            buttonText: 'Enquire now',
        },
        {
            title: 'Indian Catering Delights',
            description: 'Fine dining and eating out dont get any better than Desilicious Firangi’s Indian Catering Delights, where the flavors of India come alive on your plate. Our specialty dishes, like Dal Baati Churma, Tandoori delicacies, and a range of continental cuisines, showcase the best of traditional Indian meals, all made with authentic spices and ingredients. Experience the true taste of India with Desilicious Firangi by Rikhil’s Rasoi the next time you host an event.',
            image: serviec3,
            buttonText: 'Enquire now',
        },
    ];

    const nextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const prevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };



    return (
        <>
            <Helmet>
                <title>Catering | Foodie Buddy</title>
            </Helmet>

            <section className="common-banner common-banner-catering">
                <div className="common-banner-content">
                    <div className="common-banner-text">
                        <h2 className="common-banner-title">Catering Services in Jaipur</h2>
                        <p className='common-banner-description'>Here at Desilicious Firangi, you will get all your meals as mouth-watering as the occasion you are celebrating. Our highly skilled cooks innovate Indian and fusion food culinary services to offer the best catering services. We deal in corporate catering, wedding catering, and any other occasion that can be made tastier with our food. </p>
                        <p className="common-banner-desc"><Link to={"/"}><span >Home</span></Link><IoIosArrowForward /><span className='desc-y-c'></span>Catering</p>
                    </div>
                </div>
            </section>

            <section className="catering-service-section container c-s ">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className={`catering-service-card ${index % 2 === 1 ? 'reverse' : ''}`}
                    >
                        <img src={service.image} alt={service.title} className="service-image" />
                        <div className={`catering-service-content ${index % 2 === 0 ? 'text-right' : 'text-left'}`}>
                            <h3 className="catering-service-title">{service.title}</h3>
                            <p className="catering-service-description">{service.description}</p>
                            <button onClick={()=>Navigate("/contact")} className="c-btn">{service.buttonText}</button>
                        </div>
                    </div>
                ))}
            </section>

            <section className="catering-showcase container">
                <div className="showcase-header">
                    <CommonHeading shortTitle={"showcase"} title={"Our Catering"} />
                </div>
                <div className="showcase-content">
                    <div className="video-container">
                        {/* <img src={showcase} alt="Our Catering" className="catering-image" /> */}

                        {/* <div className="video-play-button">
                            <button aria-label="Play Video">
                                <i className="play-icon">▶</i>
                            </button>
                        </div> */}
                        <video width="695" height="409" controls preload='auto'>
                            <source src={video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="showcase-description">
                        <p className="highlight-text">We Are The Best Caterers</p>
                        <p className="description-text">
                            Watch the culinary artistry in action! It will be our pleasure to help you transform your dream dish into a reality at Desilicious Firangi by Rikhil’s Rasoi.
                        </p>
                    </div>
                </div>
            </section>

            <section className="catering-menu-section c-s">
                <div className=' container'>
                    <h2 className="catering-menu-title">Menu</h2>
                    <div className="catering-menu-content">
                        <div className="catering-menu-categories">
                            <div className="catering-category-btn" onClick={prevSlide}>
                                <FaArrowUp />
                            </div>
                            <Slider {...sliderSettings} ref={sliderRef}>
                                {categories.map((category) => (
                                    <div
                                        key={category.id}
                                        className={`catering-category-item ${activeCategory === category.id ? 'active' : ''}`}
                                        onClick={() => handleCategoryClick(category.id)}
                                    >
                                        <img src={category.icon} alt={category.name} className="catering-category-icon" />
                                        <span className="catering-category-name">{category.name}</span>
                                    </div>
                                ))}
                            </Slider>
                            <div className="catering-category-btn" onClick={nextSlide}>
                                <FaArrowDown />
                            </div>
                        </div>
                        <div className="catering-menu-items">
                            <h3 className="catering-category-title">{categories.find(cat => cat.id === activeCategory)?.name}</h3>
                            <div className="category-items-container">
                                {items[activeCategory]?.map((item, index) => (
                                    <div key={index} className="catering-menu-item">
                                        <h4 className="catering-item-title">{item.title}</h4>
                                        <p className="catering-item-description">{item.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="catering-gallery-section c-s">
                <CommonHeading title={"Events and Parties"} shortTitle={"Our Gallery"} />
                <div className="catering-gallery">
                    <div className="gallery-item events">
                        <div className="gallery-item-space">
                            <div className="overlay">
                                <h2>Events</h2>
                            </div>
                        </div>

                        <div className="gallery-item-space">
                            <img src={gallery1} alt="Events" />
                        </div>

                        <div className="gallery-item-space">
                            <img src={gallery2} alt="Events" />
                        </div>
                    </div>
                    <div className="gallery-item parties">
                        <div className="gallery-item-space">
                            <img src={gallery3} alt="Events" />
                        </div>
                        <div className="gallery-item-space">
                            <div className="overlay">
                                <h2>Parties</h2>
                            </div>
                        </div>
                        <div className="gallery-item-space">
                            <img src={gallery4} alt="Events" />
                        </div>
                    </div>
                    <div className="gallery-item restaurant">
                        <div className="gallery-item-space">
                            <img src={gallery5} alt="Restaurant" />
                        </div>
                        <div className="gallery-item-space">
                            <img src={gallery6} alt="Restaurant" />
                        </div>
                    </div>
                    <div className="gallery-item restaurant">
                        <div className="gallery-item-space">
                            <div className="overlay">
                                <h2>Restaurant</h2>
                            </div>
                        </div>

                        <div className="gallery-item-space">
                            <img src={gallery7} alt="Restaurant" />
                        </div>
                        <div className="gallery-item-space">
                            <img src={gallery8} alt="Restaurant" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="event-banner c-s">
                <div className="container">
                    <div className="event-banner-content">
                        <h2 className="event-banner-subtitle">Book today!</h2>
                        <h1 className="event-banner-title">Start Planning Your Event Today!</h1>
                        <button onClick={()=>Navigate("/contact")} className="c-btn event-banner-btn">Enquire now</button>
                    </div>
                </div>
            </section>

        </>

    )
}

export default Catering